body{
    background-color: #fcfcfc;
    margin: 3% 9%;
    -webkit-tap-highlight-color: transparent;
}
.para1 {
    color: #434343;
    font-size: 16px;
    font-weight: lighter;
    margin: 200px 190px 0px 180px;
    text-align: center;
}
.para2, .para3 {
    color: #434343;
    font-size: 16px;
    font-weight: lighter;
    margin: 10px 180px 0px 180px;
    text-align: center;
}
.para4 {
  color: #434343;
  font-size: 16px;
  font-weight: lighter;
  text-align: center;
  margin: 20px;
}
.para5 {
  color: #434343;
  font-size: 16px;
  font-weight: lighter;
  margin: 100px 180px 0px 180px;
  text-align: center;
}
.para7 {
  color: #fcfcfc;
  font-size: 16px;
  font-weight: lighter;
  margin: 10px 180px 0px 180px;
  text-align: center;
}
.para8 {
  color: #434343;
  font-size: 16px;
  font-weight: lighter;
  margin: 100px 180px 0px 180px;
  text-align: left;
}
.para9 {
  color: #434343;
  font-size: 16px;
  font-weight: lighter;
  text-align: center;
  margin: 20px;
}
.para10 {
  color: #434343;
  font-size: 16px;
  font-weight: lighter;
  text-align: center;
  margin: 20px;
}
.para11 {
  color: #434343;
  font-size: 16px;
  font-weight: lighter;
  text-align: left;
  margin: 40px;
}
a {
  color: #ED728E;
  font-size: 16px;
  font-weight: lighter;
  text-decoration: none;
}
.content a {
  color: #ED728E;
  font-size: 18px;
  text-decoration: none;
}

/*PORTFOLIO*/
.portfoliobox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  margin: 140px -200px 0px -200px;
  top: 0;
}
.portfolioimg {
  background-size: cover;
  background-position: 50% 0%;
  height: 380px;
  width: 560px;
  position: relative;
  margin: 0px 20px 0px 20px;
  z-index: 2;
  transition: all 1s ease;
}
/*PRICES*/
.prices {
  background-color: #ececec;
  border-radius: 100px;
  padding: 40px 0px 90px;
  margin: 70px 0px 0px;
}
.pricestop {
  background-color: #ED728E;
  border-radius: 100px;
  padding: 30px 40px 20px;
  margin: 20px -20px 0px;
}
.pricesimg {
  background-size: cover;
  background-position: 50% 0%;
  height: 415px;
  width: 600px;
  position: relative;
  padding: 40px 0px 0px 0px;
  z-index: 0;
  transition: all 1s ease;
}
.pricescontainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 100px -200px 0px -200px;
}
.pricesbox {
  display: flex;
  flex-basis: 550px;
  justify-content: center;
  margin: 0px 20px 0px 20px;
}
.fiverrlink {
  color: #50caa3;
  font-weight: bold;
}
.fiverrlink:hover {
  color: #fcfcfc;
  font-weight: bold;
}
/*ABOUT PAGE*/
.container{
  width: 100%;
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  background-size: cover;
  margin: 80px 0px 200px 0px;
}
.deck{
  display:flex;
  flex-wrap: wrap;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  border-radius: 10px;
  cursor: pointer;
  justify-content: center;
}
.card{
  display:flex;
  width:360px;
  height:360px;
  flex-basis: 300px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  border-radius:10px;
  margin: 80px 50px 0px 50px;
}
.face {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius:10px;
}
.back {
  overflow:hidden;
  z-index:-1;
  display: block;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  box-sizing: border-box;
  &:before{
    content: "";
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  z-index: -1;
    background-size:100% 100%;
	  background-image: url('./Images/backgroundcard.jpg');
	  -webkit-transform: rotateY(180deg);
	  -moz-transform: rotateY(180deg);
	  -ms-transform: rotateY(180deg);
	  -o-transform: rotateY(180deg);
	  transform: rotateY(180deg);
  }
}
.front, .front2 .back{
  transition: all 1s linear;
}
.front{
  background-image:url('./Images/charlieimg.jpg');
  z-index:1;
  background-size: cover;
  background-position: 50%;
}
.front2{
  background-image:url('./Images/jamieimg.jpg');
  z-index:1;
  background-size: cover;
  background-position: 50%;
}
.back{
  box-shadow: 0 0 0 #aaa;
}
.flipped, .hovercard:hover{
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  .front{
    box-shadow: 5px 5px 5px #aaa;
  }
  .back{
    box-shadow: 5px 5px 5px #aaa;
  }
}

/*TIMELINE*/ 

.timeline {
  position: relative;
  max-width: 1400px;
  margin: 0px auto 50px;
}
/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  background-color: #ED728E;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -5px;
}

/* Container around content */
.timecontainer {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* Place the container to the left */
.left {
  left: -7%;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -12px;
}

/* The actual content */
.timecontent {
  padding: 10px 0px 30px 0px;
  background-color: #ececec;
  position: relative;
  border-radius: 80px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 790px) {
/* Place the timelime to the left */
  .timeline::after {
    left: 0px;
  }

/* Full-width containers */
  .timecontainer {
    width: 100%;
    padding-left: 40px;
  }


/* Make all right containers behave like the left ones */
  .right {
    left: -7%;
  }
}

/*CONTACT PAGE*/

form {
  text-align: center;
  width: 400px;
  margin: 0 auto;
  color: #434343;
}
label {
  color: #434343;
  font-size: 16px;
}
input {
  width: 100%; 
  font-size: 14px;
  line-height: 30px;
  border: 0px; 
  color: #434343; 
  margin-bottom: 8%;
  background-color: #ececec;
  border-bottom: 1px solid #434343;
  outline: none;
}
textarea {
  width: 100%; 
  font-size: 14px; 
  border: 0px; 
  color: #434343; 
  font-family: 'Raleway',sans-serif;
  margin-bottom: 8%;
  border-bottom: 1px solid #434343;
  background-color: #ececec;
  outline: none;
}
.sendbutton {
  position: relative;
  background-color:#54b3d6;
  border-radius: 16px;
  width: 115px;
  height: 40px;
  border: none;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  color: #434343;
}
.sendbutton:hover {
  color:#fcfcfc;
  cursor: pointer;
}
.maincontent2 {
	max-width: 100%;
	display: flex;
  flex-wrap: wrap;
  justify-content: center;
	text-align: center;
  margin: 80px 0px 0px 40px;
  background-color: #ececec;
  border-radius: 100px;
  padding-top: 60px;
}
.content {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 450px;
  padding: 0px 20px;
  margin: 0px 0px 40px 0px;
}

/*PROJECT PAGES*/

.projectcontent2 {
	display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px -200px 0px -200px;
  background-size: cover;
}
.projectcontent {
  padding: 80px 0px 0px 0px;
  flex-basis: 700px;
  justify-content: center;
  border-radius: 60px;
  transition: 1s ease;
}
.projectcontentimg {
  padding: 40px 0px 0px 0px;
  justify-content: center;
  transition: 1s ease;
}
.details {
  background-color: #ececec;
  border-radius: 100px;
  padding: 25px 140px 30px 140px;
  margin: 120px 0px 80px 0px;
}
.queensestatesimage1 {
  background-image:url(./Images/Queensestates/homepage.jpg);
  background-size: cover;
  background-position: 50% 0%;
  background-color: #FFFFFF;
  height: 450px;
  width: 550px;
  position: relative;
  z-index: 2;
}
.queensestatesimage1:hover{
  cursor: pointer;
}
.queensestatesimage2 {
  background-image:url(./Images/Queensestates/valuations.jpg);
  background-size: cover;
  background-position: 50% 0%;
  background-color: #FFFFFF;
  height: 450px;
  width: 550px;
  position: relative;
  z-index: 2;
}
.queensestatesimage2:hover{
  cursor: pointer;
}
.queensestatesimage3 {
  background-image:url(./Images/Queensestates/properties.jpg);
  background-size: cover;
  background-position: 50% 0%;
  background-color: #FFFFFF;
  height: 450px;
  width: 550px;
  position: relative;
  z-index: 2;
}
.queensestatesimage3:hover{
  cursor: pointer;
}
.queensestatesimage4 {
  background-image:url(./Images/Queensestates/aboutus.jpg);
  background-size: cover;
  background-position: 50% 0%;
  background-color: #FFFFFF;
  height: 450px;
  width: 550px;
  position: relative;
  z-index: 2;
}
.queensestatesimage4:hover{
  cursor: pointer;
}
.queensestatesimage5 {
  background-image:url(./Images/Queensestates/property1.jpg);
  background-size: cover;
  background-position: 50% 0%;
  background-color: #FFFFFF;
  height: 450px;
  width: 550px;
  position: relative;
  z-index: 2;
}
.queensestatesimage5:hover{
  cursor: pointer;
}
.queensestatesimage6 {
  background-image:url(./Images/Queensestates/property2.jpg);
  background-size: cover;
  background-position: 50% 0%;
  background-color: #FFFFFF;
  height: 450px;
  width: 550px;
  position: relative;
  z-index: 2;
}
.queensestatesimage6:hover{
  cursor: pointer;
}
@media (max-width: 790px) {
.queensestatesimage1 {
  height: 350px;
  width: 450px;
}
.queensestatesimage2 {
  height: 350px;
  width: 450px;
}
.queensestatesimage3 {
  height: 350px;
  width: 450px;
}
.queensestatesimage4 {
  height: 350px;
  width: 450px;
}
.queensestatesimage5 {
  height: 350px;
  width: 450px;
}
.queensestatesimage6 {
  height: 350px;
  width: 450px;
}
}
.jptimage1 {
  background-image:url(./Images/JPT/jptimage1.png);
  background-size: cover;
  background-position: 50% 0%;
  height: 380px;
  width: 600px;
  position: relative;
  z-index: 2;
}
.jptimage1:hover{
  cursor: pointer;
}
.jptimage2 {
  background-image:url(./Images/JPT/jptimage2.png);
  background-size: cover;
  background-position: 50% 0%;
  height: 380px;
  width: 600px;
  position: relative;
  z-index: 2;
}
.jptimage2:hover{
  cursor: pointer;
}
.jptimage3 {
  background-image:url(./Images/JPT/jptimage3.png);
  background-size: cover;
  background-position: 50% 0%;
  height: 380px;
  width: 600px;
  position: relative;
  z-index: 2;
}
.jptimage3:hover{
  cursor: pointer;
}
.jptimage4 {
  background-image:url(./Images/JPT/jptimage4.png);
  background-size: cover;
  background-position: 50% 0%;
  height: 380px;
  width: 600px;
  position: relative;
  z-index: 2;
}
.jptimage4:hover{
  cursor: pointer;
}
@media (max-width: 790px) {
.jptimage1 {
  height: 350px;
  width: 450px;
}
.jptimage2 {
  height: 350px;
  width: 450px;
}
.jptimage3 {
  height: 350px;
  width: 450px;
}
.jptimage4 {
  height: 350px;
  width: 450px;
}
}

/*ANIMATIONS*/

span.offset-header {
    display: block;
    overflow: hidden;
  }
span.offset-header > span {
    animation-name: slideUp;
    animation-duration: 2.9s;
    animation-timing-function: ease-in;
    display: block;
  }
.offset-header-odd > span {
    animation-delay: 0s;
    animation-fill-mode: forwards;
  }
.offset-header-even > span {
    animation-delay: 0s;
    animation-fill-mode: both;
  }

  @keyframes slideUp {
    0%,
    50% {
      transform: translateY(100%);
      opacity: 0;
    }
     
    60%,
    100% {
      transform: translateY(0);
      opacity: 1;
    
    }
  }
  .slide-right {
    animation: 1s slide-right 0.5s forwards;
      transform:translateX(-100%);
      opacity: 0;
  }
  @keyframes slide-right {
    to {
      transform:translateX(0%);
      opacity: 1;
    }
  }
  .slide-left {
    animation: 1s slide-left 0.5s forwards;
      transform:translateX(35%);
      opacity: 0;
  }
  @keyframes slide-left {
    to {
      transform:translateX(0%);
      opacity: 1;
    }
  }
  @keyframes jumpInfinite {
    50% {
      margin-top: 0;
    }
    75% {
      margin-top: 20px;
    }
    100% {
      margin-top: 0;
    }
  }
  .animate {
    animation-duration: 0.75s;
    animation-delay: 0.5s;
    animation-name: animate-fade;
    animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
    animation-fill-mode: backwards;
  }
  .animate.glow {
    animation-name: animate-glow;
    animation-timing-function: ease;
  }
  @keyframes animate-glow {
    0% {
      opacity: 0;
      transform: scale(0.8, 0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
  .delay-1 {
    animation-delay: 0.4s;
  }
  .delay-2 {
    animation-delay: 0.8s;
  }
  .delay-3 {
    animation-delay: 1.2s;
  }


/*MEDIA SCALING FOR SMALLER SCREENS*/



/*MEDIA SCALING FOR PHONES*/

@media only screen and (max-width: 1100px) {
  body {
    margin: 3% 0%;
}
.prices h3 {
    margin: 40px 0px -120px 0px;
}
.para1 {
    width: 90%;
    margin: 220px 0px 0px 0px;
    text-align: left;
}
.para2, .para3, .para8 {
    text-align: left;
    width: 90%;
    margin: 140px 0px 0px 6%;
}
.para5 {
    text-align: left;
    width: 90%;
    margin: 80px 0px 0px 6%;
}
.para7 {
    text-align: left;
    padding-top: 60px;
    padding-bottom: 60px;
}
.para9 {
  color: #434343;
  font-size: 16px;
  font-weight: lighter;
  text-align: left;
  margin: 0px;
}
.para10, .para11 {
  color: #434343;
  font-size: 16px;
  font-weight: lighter;
  text-align: left;
  margin: 40px;
}
.projectcontent2 {
  margin: 0px 0px 0px 0px;
}
.container {
    margin: 140px 0px 200px 0px;
}
.deck {
    margin: -30px 0px 0px 0px;
}
.pricescontainer {
    margin: 140px 0px 0px 0px;
}
.maincontent2 {
  margin: 140px 0px 0px 0px;
}
.details {
  background-color: #ececec;
  border-radius: 100px;
  padding: 50px 15px 50px 35px;
  margin: 140px 0px 0px 0px;
}
.pricesimg {
  background-size: cover;
  background-position: 50% 0%;
  height: 250px;
  width: 450px;
  position: relative;
  padding: 75px 0px 0px 0px;
  z-index: 0;
  transition: all 1s ease;
}
.timecontainer {
  padding: 10px 0px 0px 32px;
}
.portfolioimg {
  background-size: cover;
  background-position: 50% 0%;
  height: 300px;
  width: 440px;
  position: relative;
  margin: 0px 20px 0px 20px;
  z-index: 2;
  transition: all 1s ease;
}
.portfoliobox {
  margin: 140px 0px 0px 0px;
}
}