.contactusbutton {
    position: relative;
    background-color:#54b3d6;
    border-radius: 16px;
    width: 115px;
    height: 40px;
    border: none;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    color: #434343;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 70px 0px 0px 0px;
}
.contactusbutton:hover {
    color:#fcfcfc;
    cursor: pointer;
}
.button1 {
    display: flex;
    justify-content: center;
    align-items: center;
}