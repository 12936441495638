.counterboxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 40px 0px 0px 0px;
  }
  .counterbox {
    background-color:#ED728E;
    padding: 20px;
    flex-basis: 200px;
    justify-content: center;
    margin: 50px;
    border: none;
    border-radius: 160px;
    transition: 1s ease;
  }
  .counterboxes .counterbox img {
    height: 60px;
    width: 60px;
    justify-content: center;
    margin-left: 35.5%;
  }
.counterboxes h3 {
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    width: 100%;
    font-weight: lighter;
    color: #fcfcfc;
    margin-top: 10px;
}
h5 {
    color: #fcfcfc;
    font-size: 20px;
    text-align: center;
    font-weight: lighter;
    margin-top: -5px;
    font-style: italic;
}
@media only screen and (max-width: 800px) {
  .counterbox {
    margin: 30px 30px 30px 35px;
  }
}