.burger-menu {
    height: 60px;
    width: 64px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    cursor: pointer;
    background-color: transparent;
    position: fixed;
    right: 0;
    z-index: 4;
}
.burger-bar {
    width: 40px;
    height: 5px;
    background-color: #5A686E;
}
.menu {
    width: 180px;
    height: 415px;
    background-color: #ececec;
    float: right;
    border-radius: 12px;
    position: fixed;
    right: 0;
    z-index: 4;
}
.menu li {
    padding: 15px 0px 0px 0px;
    margin-right: 20px;
}
.hidden {
    display: none;
}
.visible {
    display: inherit;
}
.burger-bar.clicked:nth-child(1){
    transform: rotate(45deg) translate(0.5em, 0.5em);
    transition: ease-out 0.5s;
}
.burger-bar.clicked:nth-child(2){
    transform: scale(0.01);
    transition: ease-out 0.1s;
}
.burger-bar.clicked:nth-child(3){
    transform: rotate(-225deg) translate(-1em, 1em);
    transition: ease-out 0.5s;
}
.burger-bar.unclicked{
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}
@media only screen and (min-width: 1100px) {
    .burger-menu{
        display: none;
    }
    .burger-bar {
        display: none;
    }
    .menu {
        display:none;
    }
}
@media only screen and (max-width: 1100px) {
    .burger-menu{
        margin: -100px 10px 0px 0px;
    }
    .menu {
        margin: -40px 40px 0px 0px;
    }
}