.reviews {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 60px 0px 0px 0px;
  }
  .review {
    padding: 0px 0px 10px 0px;
    flex-basis: 340px;
    justify-content: center;
    margin: 30px;
  }
  .stars {
    color: #54b3d6;
  }

  @media only screen and (max-width: 768px) {
    .reviews {
        margin: 60px 0px 60px 0px;
      }
      .review {
        padding: 30px 0px 10px 0px;
        margin: 0px;
      }
  }