nav { 
	  background-color: transparent;
    position: relative;
	  width: 100%;
	  z-index: 2;
    text-align: right;
    line-height: 16px;
}
nav li {
	  display: inline-block;
    height: 100%;
    margin: 0 20px;
}
nav .navbaritem{
    color: #434343;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: normal;
    display: inline-block;
    padding: 5px 15px;
    position: relative;
    text-decoration: none;
  }
  nav .navbaritem:hover {
   color:#54b3d6;
  }
  nav .contacthighlight:hover {
    color:#fcfcfc;
   }
  nav .active {
    color:#54b3d6;
  }
  nav .navbaritem1 {
    color: #434343;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: normal;
    display: inline-block;
    padding: 5px 15px;
    position: relative;
    text-decoration: none;
  }
  .contacthighlight {
    background-color:#54b3d6;
    border-radius: 16px;
    width: 80px;
    text-align: center;
    line-height: 30px;
  }
  h6 {
    float: left;
    font-size: 40px;
    letter-spacing: -6px;
    position: relative;
    margin: 10px 0px 0px 0px;
    color: #ED728E;
  }

  @media only screen and (max-width: 1100px) {
    h6 {
        margin: -80px 0px 0px 30px;
    }
  }

  @media only screen and (max-width: 1100px) {
    .nav-bar-whole {
      display: none;
    }
}