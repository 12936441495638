.footer {
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    margin: 120px 0px 0px 0px;
}
.footer p {
    color: #434343;
    font-size: 14px;
    text-align: center;
    line-height: 26px;
    margin: 0px 0px 0px 0px;
    font-weight: lighter;
}
.socials-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 100px 0px 60px 0px;
  }
  .newsletter {
    flex-basis: 400px;
    justify-content: center;
    margin: 0px 0px 60px 0px;
    text-align: center;
  }
  .stayconnected {
    flex-basis: 400px;
    justify-content: center;
    margin: 0px 0px 60px 0px;
    text-align: center;
  }
  .links {
    margin: 25px 0px 0px 0px;
    flex-basis: 400px;
    justify-content: center;
  }
  h4 {
    color: #434343;
    font-size: 14px;
    line-height: 30px;
    position: relative;
    width: 100%;
    font-weight: lighter;
    text-transform: uppercase;
}
.newsletter form {
    width: 300px;
    margin: 0 auto;
  }
.newsletter input {
    font-weight: lighter; 
    width: 100%; 
    font-size: 12px;
    line-height: 30px;
    border: 0px; 
    color: #434343; 
    margin: 0px 0px 10px -10px;
    background-color: #ececec;
    border-radius: 16px;
    padding-left: 20px;
  }
  .buttonnews {
    background-color:#54b3d6;
    border-radius: 16px;
    width: 80px;
    height: 30px;
    border: none;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    color: #434343;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -41px 0px 0px 224px;
}
.buttonnews:hover {
    color:#fcfcfc;
    cursor: pointer;
}
.stayconnected img {
  height: 30px;
  width: 30px;
  justify-content: center;
  padding: 10px;
  margin: -10px 0px 0px 0px;
  transition: 1s ease;
}
.stayconnected img:hover {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    transition: 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

@media only screen and (max-width: 1100px) {
.stayconnected {
  margin: 60px 0px 0px 0px;
}
}