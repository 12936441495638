h1 {
    color: #fcfcfc;
    font-size: 18px;
    text-transform: uppercase;
    position: relative;
    margin: 140px 0px 0px 145px;
    z-index: 2;
}
h2 {
    color: #fcfcfc;
    font-size: 32px;
    position: relative;
    margin: 20px 0px 0px 140px;
    z-index: 2;
    text-transform: uppercase;
}
p {
    color: #fcfcfc;
    font-size: 23px;
    font-weight: lighter;
    position: relative;
    margin: 20px 0px 0px 140px;
    z-index: 2;
}
.textbackground3 p {
    color: #fcfcfc;
    font-size: 20px;
    font-weight: lighter;
    position: relative;
    margin: 0px 0px 0px 0px;
    padding: 32px 0px 0px 0px;
    z-index: 2;
    text-align: center;
}
.headerimage {
    position: relative;
    width: 775px;
    height: 500px;
    margin: -300px 0px 0px 270px;
    z-index: 0;
}
.textbackground {
    position: relative;
    background-color: #5A686E;
    border-radius: 190px;
    height: 380px;
    width: 380px;
    margin: -300px 0px 0px 80px;
    z-index: 1;
}
.textbackground2 {
  position: relative;
  background-color: #ED728E;
  border-radius: 190px;
  height: 280px;
  width: 280px;
  margin: -490px 0px 0px 80px;
  z-index: 1;
}
.textbackground3 {
  position: relative;
  background-color: #5A686E;
  border-radius: 190px;
  height: 150px;
  width: 150px;
  margin: 100px 0px 0px 930px;
  z-index: 1;
}
.reference {
    position: absolute;
    margin-top: 150px;
}
.down-arrow {
    position: absolute;
    top: calc(95% - 60px);
    left: calc(50.5% - 14px);
    width: 0;
    height: 30px;
    border: 2px solid #54b3d6;
    border-radius: 2px;
    animation: jumpInfinite infinite;
}
  .down-arrow:after {
    content: " ";
    position: absolute;
    top: 12px;
    left: -10px;
    width: 16px;
    height: 16px;
    border-bottom: 4px solid #54b3d6;
    border-right: 4px solid #54b3d6;
    border-radius: 4px;
    transform: rotateZ(45deg);
}
.down-arrow:hover {
    cursor: pointer;
}

/*MEDIA QUERIES*/
/*For Large Screens*/
@media only screen and (max-width: 4000px) {
  .headerwrapperdiv {
    margin: 0px 0px 0px 720px;
  }
}
@media only screen and (max-width: 3800px) {
  .headerwrapperdiv {
    margin: 0px 0px 0px 660px;
  }
}
@media only screen and (max-width: 3600px) {
  .headerwrapperdiv {
    margin: 0px 0px 0px 580px;
  }
}
@media only screen and (max-width: 3400px) {
  .headerwrapperdiv {
    margin: 0px 0px 0px 480px;
  }
}
@media only screen and (max-width: 3200px) {
  .headerwrapperdiv {
    margin: 0px 0px 0px 400px;
  }
}
@media only screen and (max-width: 3000px) {
  .headerwrapperdiv {
    margin: 0px 0px 0px 360px;
  }
}
@media only screen and (max-width: 2700px) {
  .headerwrapperdiv {
    margin: 0px 0px 0px 280px;
  }
}
@media only screen and (max-width: 2500px) {
  .headerwrapperdiv {
    margin: 0px 0px 0px 200px;
  }
}
@media only screen and (max-width: 2300px) {
  .headerwrapperdiv {
    margin: 0px 0px 0px 160px;
  }
}
@media only screen and (max-width: 2100px) {
  .headerwrapperdiv {
    margin: 0px 0px 0px 60px;
  }
}
@media only screen and (max-width: 1900px) {
  .headerwrapperdiv {
    margin: 0px 0px 0px 0px;
  }
}
@media only screen and (max-width: 1700px) {
  .headerwrapperdiv {
    margin: 0px 0px 0px -60px;
  }
}
@media only screen and (max-width: 1500px) {
  .headerwrapperdiv {
    margin: 0px 0px 0px -120px;
  }
}
@media only screen and (max-width: 1300px) {
  .headerwrapperdiv {
    margin: 0px 0px 0px -210px;
  }
}
@media only screen and (max-width: 1100px) {
  .headerwrapperdiv {
    margin: 0px 0px 0px 0px;
  }
}

/*For Mobile Screens*/
@media only screen and (max-width: 1100px) {
    h1 {
        margin: 140px 0px 0px 50px;
        text-align: left;
        font-size: 30px;
        padding-top: 100px;
        font-weight: bold;
    }
    h2 {
        margin: 50px 0px 50px 0px;
        font-size: 48px;
        text-align: center;
        text-transform: uppercase;
    }
    p {
        margin: 20px 0px 0px 50px;
        font-size: 20px;
        font-weight: bold;
        text-align: left;
    }
    .headertext p {
        font-size: 20px;
        text-transform: uppercase;
    }
    .headerimage {
        position: relative;
        object-fit: cover;
        width: 100%;
        height: 500px;
        margin: -400px 0px -20px 0px;
        filter: brightness(50%);
    }
    .textbackground {
        margin: -320px 0px 0px 56px;
        display: none;
    }
    .textbackground2 {
        margin: -260px 0px 0px 40px;
        display: none;
    }
    .textbackground3 {
        margin: -160px 0px 0px 240px;
        display: none;
    }
    .down-arrow {
        display: none;
    }
    .reference {
        margin-top: 10px;
    }
    .para1 {
        margin: 50px 0px 0px 30px;
    }
    .headerwrapperdiv {
      margin: 0px 0px 0px -10px;
    }
  }


/*ANIMATIONS*/

span.offset-header {
    display: block;
    overflow: hidden;
  }
span.offset-header > span {
    animation-name: slideUp;
    animation-duration: 2.9s;
    animation-timing-function: ease-in;
    display: block;
  }
.offset-header-odd > span {
    animation-delay: 0s;
    animation-fill-mode: forwards;
  }
.offset-header-even > span {
    animation-delay: 0s;
    animation-fill-mode: both;
  }

  @keyframes slideUp {
    0%,
    50% {
      transform: translateY(100%);
      opacity: 0;
    }
     
    60%,
    100% {
      transform: translateY(0);
      opacity: 1;
    
    }
  }
  .slide-right {
    animation: 1s slide-right 0.5s forwards;
      transform:translateX(-100%);
      opacity: 0;
  }
  @keyframes slide-right {
    to {
      transform:translateX(0%);
      opacity: 1;
    }
  }
  .slide-left {
    animation: 1s slide-left 0.5s forwards;
      transform:translateX(35%);
      opacity: 0;
  }
  @keyframes slide-left {
    to {
      transform:translateX(0%);
      opacity: 1;
    }
  }
  @keyframes jumpInfinite {
    50% {
      margin-top: 0;
    }
    75% {
      margin-top: 20px;
    }
    100% {
      margin-top: 0;
    }
  }
  .animatearrow {
    animation-duration: 1.8s;
    animation-delay: 1.5s;
    animation-name: animate-fade;
    animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
    animation-fill-mode: backwards;
  }
  .animatearrow.glowarrow {
    animation-name: animatearrow-glowarrow;
    animation-timing-function: ease;
  }
  @keyframes animatearrow-glowarrow {
    0% {
      opacity: 0;
      transform: scale(0.8, 0.8);
    }
    40% {
      opacity: 1;
      transform: scale(1, 1);
    }
    50% {
        margin-top: 0;
      }
    75% {
        margin-top: 20px;
      }
    100% {
        margin-top: 0;
      }
    }
    @keyframes jumpInfinite {
    50% {
      margin-top: 0;
    }
    75% {
      margin-top: 20px;
    }
    100% {
      margin-top: 0;
    }
  }
  .delay-1 {
    animation-delay: 0.4s;
  }
  .delay-2 {
    animation-delay: 0.8s;
  }
  .delay-3 {
    animation-delay: 1.2s;
  }