.section-wrapper {
    display: flex;
    flex-wrap: wrap;
    background-size: cover;
    margin: 40px 0px 0px 0px;
  }
  .section-1 {
    flex-basis: 50%;
    justify-content: center;
  }
  .section-2 {
    flex-basis: 50%;
    justify-content: center;
  }
  h3 {
    color: #434343;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
	  position: relative;
    width: 100%;
    font-weight: lighter;
  }
#tech-container {
    background-color: #ececec;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
  .tech-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-size: cover;
    padding: 20px 0px 80px 0px;
  }
  .tech-box {
    padding: 40px 25px 0px 25px;
    flex-basis: 100px;
    justify-content: center;
    margin: 20px 0px 20px 20px;
    transition: 1s ease;
  }
  .tech-boxes .tech-box img {
    height: 60px;
    width: 60px;
    justify-content: center;
    border-radius: 12px;
    padding: 10px;
  }
  .tech-box1 {
    padding: 40px 25px 0px 25px;
    flex-basis: 100px;
    justify-content: center;
    margin: 20px 0px 20px 20px;
    transition: 1s ease;
  }
  .tech-boxes .tech-box1 img {
    height: 60px;
    width: 45px;
    justify-content: center;
    padding: 10px 0px 10px 20px;
  }
  .tech-box:hover{
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    transition: 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
  }
  .tech-box1:hover{
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    transition: 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
  }
  #steps {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 164px 0px;
    background-color: #ececec;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .stepsboxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    counter-reset: li; 
    list-style: none; 
    padding: 0px 80px 0px 80px;
    margin: 100px 0px 0px 0px;
  }
  .stepsboxes li {
    position: relative;
    display: block;
    padding: .4em .4em .4em .8em;
    margin: 2em 0 0em 2.5em;
    background: #fcfcfc;
    border-radius: 22px;
    transition: all 0s ease-out;
    text-align: center;
    font-size: 14px;
    color: #434343;
  }
  .stepsboxes li:hover {color: #434343;}       
  .stepsboxes li:before {
    content: counter(li);
    counter-increment: li;
    position: absolute;
    left: -2.5em;
    top: 50%;
    margin-top: -1em;
    background: #5A686E;
    border-radius: 22px;
    height: 2em;
    width: 2em;
    line-height: 2em;
    text-align: center;
    transition: all 0s ease-out;
    color: #fcfcfc;
  }
  .stepsboxes li:after {
    position: absolute;
    content: "";
    border: .5em solid transparent;
    left: -1em;
    top: 50%;
    margin-top: -.5em;
    transition: all .3s ease-out;
  }
  .stepsbox1 {
    flex-basis: 450px;
    justify-content: center;
  }
  .stepsbox2 {
    flex-basis: 450px;
    justify-content: center;
  }

  @media only screen and (max-width: 1600px) {
      .section-1 {
        flex-basis: 100%;
      }
      .section-2 {
        flex-basis: 100%;
      }
      #tech-container {
        margin: -150px 0px 0px 0px;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
      }
      #steps {
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
      }
  }

  @media only screen and (max-width: 1600px) {
  .stepsboxes {
    padding: 0px 60px 0px 60px;
  }
}